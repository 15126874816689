<template>
  <div class="container">
    <div class="stocks__title title">Акции</div>
    <div class="stocks">
      <router-link
        :to="{ name: 'stock', params: { code: item.code } }"
        v-for="item in stocks"
        :key="item.id"
        class="stock"
      >
        <span class="stock__image">
          <img :src="item.picture" :alt="item.name" />
        </span>
        <span class="stock__text title title--second">
          {{ item.name }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";

export default {
  // beforeRouteEnter(to, from, next) {
  //   if (!store.state.stocks.isLoadedStocks)
  //     store.dispatch("STOCKS_FETCH_DATA").then(() => {
  //       next();
  //     });
  //   else next();
  // },

  beforeRouteEnter(to, from, next) {
    store.dispatch("STOCKS_FETCH_DATA").then(() => {
      next();
    });
  },

  mounted() {
    this.$store.dispatch("STOCKS_FETCH_DATA");
  },

  computed: {
    stocks() {
      return this.$store.state.stocks.stocks;
    },
  },
};
</script>
